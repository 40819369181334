//google settings are defined in the iife argument at the bottom.
//set your client idscopes and api key there.
//config.immediate should be set to true.
var trelloConnect = (function (settings) {
	'use strict';

	var userTokenMap = {};
	var userProfile;
	var members;
	var baseURL = _CONFIG.DBK_BASEURL || `//${window.location.host}/`;
	var errorReporter;

	var tokenWatcher;

	return {
		clearTokenMemory: clearTokenMemory,
		setErrorReporter: setErrorReporter,
		settings: settings,
		auth: auth,
		deauthorize: deauthorize,
		getUserProfile: getUserProfile,
		getMembers: getMembers,

		//calendar functions
		calendarList: calendarList,
		eventList: eventList,
		getEvent: getEvent,
		updateEvent: updateEvent,
		createEvent: createEvent,
		deleteEvent: deleteEvent,
		changeCalendar: changeCalendar,
		updateCalendar: updateCalendar,
		updateCalendarList: updateCalendarList,
		ajaxRequest: ajaxRequest,
	};

	//Public Functions******************************************

	function clearTokenMemory() {
		settings.token = null;
		settings.config.immediate = false;
	}

	function setErrorReporter(errorReportingFunction) {
		errorReporter = errorReportingFunction;
	}

	function getUserProfile() {
		return userProfile;
	}

	function getMembers() {
		return members;
	}

	function isSalesforce() {
		return fbk.isSalesforce();
	}

	//Google Authorization routine.
	//Try to get the token silently, if that fails then callback should present button for auth - statusOnly is boolean to avoid an attempt to sign in
	function auth(
		userID,
		sourceID,
		statusOnly,
		forceConsent,
		redirectAuth,
		redirectAuthFunction,
		callback
	) {
		// var forceRedirect = (redirectAuth || isStandalone()) && !isSalesforce();
		var forceRedirect = redirectAuth && !isSalesforce();
		var salesforceMobileRedirect = isMobile() && isSalesforce();
		var scope = 'calendar';

		if (statusOnly) {
			settings.config.immediate = true;
		}

		if (forceConsent) {
			settings.config.immediate = false;
		}

		if (!settings.config.immediate && forceRedirect) {
			authRedirect(sourceID, userID, function (result) {
				check(result, callback);
			});
		}
		// else if (!settings.config.immediate && salesforceMobileRedirect) {
		//Enable this if there is no way to tell the difference between the SF mobile app and the SF1 Web page
		// 	salesforceMobileAuthRedirect(sourceID, userID, callBack);
		// }
		else if (!settings.config.immediate) {
			authPopup(sourceID, userID, function (result) {
				check(result, callback);
			});
		} else if (!settings.token) {
			// Attempt to get new token from server
			updateToken(userID, sourceID, function (result) {
				check(result, callback);
			});
		} else {
			callback(true);
		}

		function authRedirect(sourceID, userID, callback) {
			var currentURL = window.location.href;
			var redirectURL =
				baseURL +
				'api/trello/auth?scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&clientRedirectURL=' +
				encodeURIComponent(currentURL);

			if (redirectAuthFunction) {
				redirectURL += '&type=redirectFunction';
				redirectAuthFunction(redirectURL, function () {
					// Wrapped in a timeout because sometimes the read from firebase happens before the data is available.
					// Todo figure out a better way than a delay here to ensure the token is ready to be fetched
					window.setTimeout(() => {
						updateToken(userID, sourceID, function (result) {
							check(result, function (result) {
								callback(result);
							});
						});
					}, 500);
				});
			} else {
				redirectURL += '&type=redirect';
				window.location.href = redirectURL;
			}
		}

		function authPopup(sourceID, userID, callback) {
			var popup = window.open(
				baseURL +
					'api/trello/auth?type=popup&scope=' +
					scope +
					'&sourceID=' +
					encodeURIComponent(sourceID) +
					'&userID=' +
					encodeURIComponent(userID),
				'',
				'width=500, height=690'
			);
			popup.onload = function () {
				// Check for an error loading the url
				var documentTitle = popup.document.title;
				if (documentTitle === '502 Bad Gateway') {
					popup.close();
					errorReporter(502);
				}
			};

			let popoverCheckTimout;
			window.addEventListener('message', (e) => {
				const token = e.data;
				const returnParams = {
					access_token: token,
					expiry_date: Date.now() + 1000 * 60 * 60,
				};
				const successURL =
					baseURL +
					`api/trello/auth-success?token=${token}&userID=${userID}&sourceID=${sourceID}&forceClose=true`;

				if (popoverCheckTimout) {
					window.clearTimeout(popoverCheckTimout);
				}

				popup.location.href = successURL;
				// popup?.close();

				applyToken(userID, sourceID, returnParams, null);

				callback(returnParams);
			});

			popoverCheck(popup, callback);

			function popoverCheck(popupWindow, callback) {
				popoverCheckTimout = window.setTimeout(function () {
					if (popupWindow?.closed) {
						// If window was closed run the callback with no result
						callback();
					} else {
						popoverCheck(popupWindow, callback);
					}
				}, 250);
			}
		}

		function salesforceMobileAuthRedirect(sourceID, userID, callback) {
			var hidden = false;
			var url =
				baseURL +
				'api/trello/auth?type=popup&scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&forceClose=true';

			//Add a listener for popup window closing
			document.addEventListener('visibilitychange', onchange);

			//Open new auth window
			fbk.publish('dbk.navigate', {url: url, new: true});

			function onchange(e) {
				hidden = !hidden;
				if (!hidden) {
					document.removeEventListener('visibilitychange', onchange);
					auth(userID, sourceID, true, false, callback);
				}
			}
		}
	}

	function updateToken(userID, sourceID, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		ajaxRequest({
			url: baseURL + 'api/trello/token',
			type: 'GET',
			params: params,
			onSuccess: function (token) {
				const returnParams = {
					access_token: token,
					expiry_date: Date.now() + 1000 * 60 * 60,
				};
				applyToken(userID, sourceID, returnParams, callback);
			},
			onError: function (error) {
				console.log(error);
				if (callback) {
					callback(false);
				}
			},
		});
	}

	function applyToken(userID, sourceID, tokens, callback) {
		var fetchMinutesBeforeExpires = 5;
		var now = new Date().getTime();

		var token;
		var expires;

		var updateTimeout;

		// Clear any pending timeouts
		clearTimeout(tokenWatcher);

		if (
			tokens &&
			tokens.access_token &&
			tokens.expiry_date &&
			!tokens.error
		) {
			token = tokens.access_token;
			expires = Number(tokens.expiry_date);

			updateTimeout =
				expires - now - 1000 * 60 * fetchMinutesBeforeExpires;

			// Remove old token from user token map if it exists
			if (userTokenMap[settings.token]) {
				delete userTokenMap[settings.token];
			}

			// Set the token to global settings
			settings.token = token;
			settings.tokenexpires = now + (expires - 60) * 1000;

			userTokenMap[token] = {
				token: token,
				expires: expires,
				userID: userID,
				sourceID: sourceID,
			};

			settings.config.immediate = true;

			// Set a timeout so we can update the token before it expires
			tokenWatcher = window.setTimeout(function () {
				updateToken(userID, sourceID);
			}, updateTimeout);
		} else {
			// Set to true here as we'll be recalling without re-loading the page
			settings.config.immediate = true;
		}

		if (callback) {
			callback(token);
		}
	}

	// callback for checking our silent try
	function check(result, callback) {
		if (result && !result.error) {
			// we're already authorized in.
			userReq(callback);
		} else {
			//set settings.config.immediate to false so next check brings up the pop-over
			//false callBack should trigger showing the auth button
			settings.config.immediate = false;
			if (callback) {
				callback(false);
			}

			return false;
		}
	}

	// get user info
	function userReq(callback) {
		var params = {
			token: settings.token,
			key: settings.apiKey,
		};

		ajaxRequest({
			url: `${settings.apiURL}members/me/`,
			type: 'GET',
			params: params,
			onSuccess: function (response) {
				userProfile = response;
				getAllMembers(callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});
	}

	function deauthorize(userID, sourceID, switchAccount, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		if (switchAccount) {
			processDeauthorize(callback);
			return;
		}

		ajaxRequest({
			url: baseURL + 'api/trello/token',
			type: 'DELETE',
			params: params,
			onSuccess: function (response) {
				processDeauthorize(callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processDeauthorize(callback) {
			if (callback) {
				settings.token = null;
				if (!switchAccount) {
					settings.config.immediate = false;
				}
				callback();
			}
		}
	}

	//Public Calendar Functions

	//Load Calendar Lists
	function calendarList(callBack, params, retryCount) {
		//add token to params
		if (!params) {
			params = {};
		}
		params.token = settings.token;
		params.key = settings.apiKey;
		params.lists = 'all';

		ajaxRequest({
			url: `${settings.apiURL}members/me/boards`,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				console.log('all boards', response);
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	function getAllMembers(callback, params) {
		//add token to params
		if (!params) {
			params = {};
		}
		params.token = settings.token;
		params.key = settings.apiKey;

		const organizations = userProfile.idOrganizations;

		ajaxRequest({
			url: `${settings.apiURL}organizations/${organizations[0]}/members`,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				console.log('response', response);
				members = response;
				callback(true);
			},
			onError: function (error) {
				console.log(error);
				members = [];
				callback(false);
			},
		});
	}

	//get specified event. Used for getting original event from repetition series.
	function getEvent(calendarId, eventId, params, callback) {
		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				encodeURIComponent(eventId),
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processResponse(data) {
			callback(data);
		}
	}

	//Load Events Lists
	function eventList(calendarId, fieldMap, callBack, params, retryCount) {
		//add token to params
		if (!params) {
			params = {};
		}
		params.token = settings.token;
		params.key = settings.apiKey;

		ajaxRequest({
			url: `${settings.apiURL}boards/${calendarId}/cards`,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				console.log('response', response);
				checkResult(response);
			},
			onError: function (error) {
				console.log(error);
				checkResult({error: error});
			},
		});

		//internal call back to get more results.
		function checkResult(result) {
			var items = [];
			processResult(result);

			function processResult(result) {
				//Exit with callback if we have an error
				if (result.error) {
					if (callBack) {
						callBack(result);
					}
					return;
				}

				if (!result.items) {
					result.items = items;
					callBack(result);
					return;
				}

				if (resultClass && settings.mapsOn === true) {
					for (var i = 0; i < result.items.length; i++) {
						if (result.items[i].status !== 'cancelled') {
							items.push(
								transformIn(
									result.items[i],
									resultClass,
									fieldMap
								)
							);
						}
					}
				} else {
					for (var i = 0; i < result.items.length; i++) {
						items.push(result.items[i]);
					}
				}

				if (result.nextPageToken) {
					// we have more results to get
					params.pageToken = result.nextPageToken;

					ajaxRequest({
						url:
							'https://www.googleapis.com/calendar/' +
							settings.calendarApi +
							'/calendars/' +
							encodeURIComponent(calendarId) +
							'/events',
						type: 'GET',
						params: params,
						onSuccess: function (response) {
							processResult(response);
						},
						onError: function (error) {
							console.log(error);
						},
					});
				} else {
					result.items = items;
					callBack(result); // external callback
				}
			}
		}

		function newObject() {
			var o = {};
			return o;
		}
	}

	//Update Event
	function updateEvent(
		calendarId,
		eventId,
		request,
		eventData,
		fieldMap,
		callback,
		params,
		retryCount
	) {
		console.log('eventdata', eventData);
		console.log('event request', request);
		//add token to params
		if (!params) {
			params = {};
		}
		//add token to query
		params.token = settings.token;
		params.key = settings.apiKey;
		params = {...params, ...request};
		console.log('params', params);

		ajaxRequest({
			url: `${settings.apiURL}/cards/${eventId}`,
			type: 'PUT',
			params: params,
			// data: request,
			retryCheck: retryCheck,
			preventErrorReporter: true,
			onSuccess: function (response) {
				callback(response);
			},
			onError: function (error) {
				if (error && error.preventDeauth) {
					callback({error: error});
				}
			},
		});
	}

	//Update Event
	function createEvent(
		calendarId,
		request,
		eventData,
		fieldMap,
		callback,
		params,
		retryCount
	) {
		if (!params) {
			params = {};
		}
		//add token to query
		params.token = settings.token;
		params.key = settings.apiKey;
		params = {...params, ...request};

		ajaxRequest({
			url: `${settings.apiURL}/cards/`,
			type: 'POST',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				callback(response);
			},
			onError: function (error) {
				if (!error) {
					error = {
						error: {
							message: 'Could not connect to the Google servers',
						},
					};
				} else {
					error = {error: error};
				}
				callback(error);
			},
		});
	}

	function updateCalendarList(calendarId, request, callBack, retryCount) {
		var params = {};

		//add token to params
		params = {access_token: settings.token};

		if (request['backgroundColor']) {
			if (request['backgroundColor'].length) {
				params['colorRgbFormat'] = true;
			} else {
				params['colorRgbFormat'] = false;
			}
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/users/me/calendarList/' +
				encodeURIComponent(calendarId),
			type: 'PATCH',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	function updateCalendar(calendarId, request, callBack, retryCount) {
		//add token to params
		var params = {access_token: settings.token};

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId),
			type: 'PATCH',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process responce result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	//change the event a calendar is attaches to.
	function changeCalendar(
		eventId,
		calendarId,
		newCalendarId,
		fieldMap,
		callBack,
		retryCount
	) {
		//using a custom result
		if (settings.mapIn) {
			var resultClass = settings.mapIn;
		}

		var params = {
			access_token: settings.token,
			destination: newCalendarId,
		};

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				encodeURIComponent(eventId) +
				'/move',
			type: 'POST',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			//Exit with callback if we have an error
			if (result.error) {
				callBack(result);
				return;
			}

			if (resultClass && settings.mapsOn === true) {
				callBack(transformIn(result, resultClass, fieldMap));
			} else {
				callBack(result);
			}
		}
	}

	//delete the specified event
	function deleteEvent(calendarId, eventId, callBack, retryCount) {
		const params = {
			token: settings.token,
			key: settings.apiKey,
		};

		ajaxRequest({
			url: `${settings.apiURL}/cards/${eventId}`,
			type: 'DELETE',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	function retryCheck(result) {
		console.log('gcal error result', result);

		if (!result) {
			return;
		}
		var error = result.error;
		var errorResult;
		var reAuth;
		var message;
		var preventDeauth;

		if (error) {
			message = error.message;
			reAuth = Number(error.code) === 401 || Number(error.code) === 403;

			errorResult = {
				reAuth: reAuth,
				preventDeauth: preventDeauth,
				code: error ? Number(error.code) : null,
				message: message ? message : '',
			};
		}

		return errorResult;
	}

	//Private Calendar Functions******************************************
	function ajaxRequest(options, retryCount) {
		// parameter object options:
		//{url, type, params, data, retryCheck, preventErrorReporter, onSuccess, onError}

		var responseResult;
		var paramList = [];
		var data;
		var type;
		var retryCheckResult;
		var url = options.url;
		var code;
		var message;
		var preventDeauth;

		if (!options) {
			return;
		}

		if (!retryCount) {
			retryCount = 0;
		}

		// Build parameter string and append to url
		if (options.params) {
			url += '?';

			for (var param in options.params) {
				paramList.push(
					encodeURIComponent(param) +
						'=' +
						encodeURIComponent(options.params[param])
				);
			}

			url += paramList.join('&');
		}

		type = options.type ? options.type.toUpperCase() : 'GET';

		var xhr = new XMLHttpRequest();
		xhr.open(type, url);

		if (options.data) {
			try {
				data = JSON.stringify(options.data);
				xhr.setRequestHeader('Content-Type', 'application/json');
			} catch (error) {
				// Could perform an action if options is not an object
				data = options.data;
				xhr.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded'
				);
			}
		}

		xhr.onreadystatechange = function (e) {
			if (xhr.readyState == 4) {
				try {
					responseResult = JSON.parse(xhr.response);
				} catch (error) {
					responseResult = xhr.response;
				}

				if (
					xhr.status == 200 ||
					(type === 'DELETE' && xhr.status == 204)
				) {
					if (
						options.onSuccess &&
						(xhr.response ||
							(type === 'DELETE' && xhr.status == 204))
					) {
						retryCheckResult = options.retryCheck
							? options.retryCheck(responseResult)
							: null;

						if (
							retryCheckResult &&
							retryCount < settings.retryLimit
						) {
							if (retryCheckResult.reAuth) {
								authAndRetry();
							} else {
								retry();
							}

							return;
						}

						options.onSuccess(responseResult);
					}
				} else {
					retryCheckResult = options.retryCheck
						? options.retryCheck(responseResult)
						: null;
					preventDeauth =
						retryCheckResult && retryCheckResult.preventDeauth;

					// Check for an invalid token response and attempt to renew the token. Limit to 3 retries
					if (
						((retryCheckResult && retryCheckResult.reAuth) ||
							(options.params.access_token &&
								!retryCheckResult &&
								(Number(xhr.status) === 401 ||
									Number(xhr.status) === 403))) &&
						retryCount < settings.retryLimit &&
						userTokenMap[settings.token]
					) {
						authAndRetry();
					} else if (
						retryCheckResult &&
						retryCount < settings.retryLimit
					) {
						retry();
					} else if (options.onError) {
						if (
							(options.preventErrorReporter && !preventDeauth) ||
							!options.preventErrorReporter
						) {
							errorReporter(
								xhr.status,
								retryCheckResult && retryCheckResult.message,
								preventDeauth
							);
						}
						options.onError(
							retryCheckResult || xhr.response,
							xhr.status,
							preventDeauth
						);
					}
				}
			}
		};
		xhr.send(data);

		function authAndRetry() {
			window.setTimeout(function () {
				updateToken(
					userTokenMap[settings.token].userID,
					userTokenMap[settings.token].sourceID,
					function (token) {
						options.params.access_token = token;
						ajaxRequest(options, retryCount + 1);
					}
				);
			}, settings.retryWait);
		}

		function retry() {
			window.setTimeout(function () {
				ajaxRequest(options, retryCount + 1);
			}, settings.retryWait);
		}
	}

	function getURLParams(url) {
		if (!url) {
			return {};
		}
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = url.substring(1);

		var urlParams = {};
		while ((match = search.exec(query))) {
			urlParams[decode(match[1])] = decode(match[2]);
		}
		return urlParams;
	}

	function isStandalone() {
		var standalone =
			'standalone' in window.navigator && window.navigator.standalone;
		return standalone;
	}

	function isIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	function isMobile() {
		return isIos() || isAndroid() || isWindowsPhone();
	}

	function isIos() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipod|ipad/.test(userAgent) && !window.MSStream;
	}

	function isAndroid() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /android/i.test(userAgent);
	}

	function isWindowsPhone() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /windows phone/i.test(userAgent);
	}
})(
	(function () {
		//begin defining default google settings
		var settings = {
			config: {
				client_id: '',
				scope: '',
				immediate: true, //set to true to attempt token retrieval without the pop-up
			},

			apiURL: 'https://api.trello.com/1/',
			apiKey: 'b7ad8982e819eea706f18d6db076c8e6',
			token: '',
			validtoken: null,
			tokenexpires: 0,

			userinfo: null,
			timezone: '',

			retryLimit: 2,
			retryWait: 500,

			callBack: null,

			internalCallBack: null,

			calendarList: {},
		};
		//end defining google settings

		return settings;
	})()
);
